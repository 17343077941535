// Navbar
.nav-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  padding: 10px 0px;
  background: linear-gradient(91deg, #2c89ad -16.66%, #202527 121.11%);
  display: none;
  position: sticky;
  @include media("<=tablet") {
    display: flex;
  }
  .wrap {
    .nav-links {
      position: relative;
      gap: 12px;
      padding: 0 3%;
      .nav-item {
        flex: 1;
        position: relative;
        .icon {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background: transparent;
          @include anim;
          &.active {
            height: 60px;
            width: 70px;
            top: -58px;
            position: absolute;

            background-image: url("../../assets/images/shape.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            position: absolute;
          }
        }
      }
    }
  }
}
