.kyc-approvals-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  background-color: #1F2325;
  .wrap {
    .dashboard-box {
      min-height: 730px;
      width: 100%;
      border-radius: 18px;
      background-color: #fff;
      @include media("<=tablet") {
        padding: 20px 4px;
        min-width: 0;
      }
      .dashboard-header {
        padding: 30px 60px;
        display: flex;
        @include media("<=tablet") {
          padding: 10px 2px;
        }
        .heading {
          color: #202527;
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          @include media("<=tablet") {
            font-size: 20px;
          }
        }
      }
      .d-list {
        // max-width: 600px;
        max-height: 600px;
        overflow: hidden;
        overflow-y: auto;
        padding: 30px 60px;
        margin-top: 40px;
        @include media("<=tablet") {
          padding: 10px;
          margin-top: 20px;
        }
        .expendable-block {
          border-radius: 6px;
          border: 1px solid #1c0057;
          background: linear-gradient(0deg, #edf2f6 0%, #edf2f6 100%), #fff;
          .img-block {
            @include media("<=desktop") {
              flex-direction: column;
            }
            .images-selection-block {
              @include media("<=desktop") {
                flex-direction: column;
              }
            }
            .img-shape {
            }

            .u-img {
            }
          }
          .btn-approve {
            background: #1c0057;
          }
        }
      }
    }
    .table-block {
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      .table-head {
        padding: 12px 0;
        background: linear-gradient(91deg, #2c89ad -16.66%, #202527 121.11%);
        th {
          padding: 18px;
          color: #fff;
        }
      }
      .table-body {
        padding: 12px 0;
        td {
          padding: 14px;
          font-size: 13px;
        }
        .btn {
          font-size: 10px;
          padding: 8px;
          background: #ff0000;

          &:last-child {
            background: #13c604;
          }
        }
      }
      tbody tr:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }
  }
}
